<template>
  <v-container
    id="wizard"
    tag="section"
    fluid
    class="blanketaccent"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="10"
      >
        <v-card
          style="border-radius: 20px;"
          elevation="0"
        >
          <v-card
            min-height="300"
            elevation="0"
          >
            <div
              class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
            >
              Direct agent processing
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="10"
                  md="10"
                  lg="10"
                >
                  <v-divider
                    class="test text-center"
                  />
                </v-col>
              </v-row>
            </div>
            <div
              class="text-center text-h2 font-weight-light mb-6 mt-4 blanketbutton--text"
            >
              <v-row justify="center">
                <v-col
                  cols="12"
                  sm="8"
                  md="8"
                >
                  <v-row justify="center">
                    <v-dialog
                      v-model="dialog"
                      persistent
                      max-width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Open Dialog
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Symetra Never ending question loop POC
                        </v-card-title>
                        <v-card-text>
                          <div class="text-h2 pa-12">
                            {{ followQuestion.question }}
                          </div>
                        </v-card-text>
                        <v-card-text
                          v-if="followQuestion.style !== null && followQuestion.style === 'Checkbox'"
                        >
                          <div
                            v-for="(item,index) in followQuestion.options"
                            :key="index"
                          >
                            <v-checkbox
                              v-model="follow[followQuestion.apiId + index]"
                              :label="`Checkbox 1: ${item.toString()}`"
                            ></v-checkbox>
                          </div>
                        </v-card-text>
                        <v-card-text
                          v-if="followQuestion.style !== null && followQuestion.style === 'Select'"
                        >
                          <v-select
                            :items="followQuestion.options"
                            label="Answer Style"
                          ></v-select>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="testrepeat"
                          >
                            Answer with Followup
                          </v-btn>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="dialog = false"
                          >
                            Give up
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                  <v-form>
                    <v-container class="py-0">
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <div>
                            Thank you, your answers indicate the need for direct agent handling.
                          </div>
                          <v-img src="/img/logo.svg" />
                          <div>
                            Once you submit the request, an agent will contact you shortly
                          </div>
                          <v-dialog
                            transition="dialog-bottom-transition"
                            max-width="600"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                v-bind="attrs"
                                persistent
                                v-on="on"
                                @click="testrepeat"
                              >
                                From the bottom
                              </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                              <v-card>
                                <v-toolbar
                                  color="primary"
                                  dark
                                >
                                  Symetra Follow Up question POC
                                </v-toolbar>
                                <v-card-text>
                                  <div class="text-h2 pa-12">
                                    {{ followQuestion.question }}
                                  </div>
                                </v-card-text>
                                <div
                                  v-if="followQuestion.style !== null && followQuestion.style === 'Checkbox'"
                                >
                                  <div
                                    v-for="(item,index) in followQuestion.options"
                                    :key="index"
                                  >
                                    <v-checkbox
                                      v-model="follow[followQuestion.apiId + index]"
                                      :label="`Checkbox 1: ${item.toString()}`"
                                    ></v-checkbox>
                                  </div>
                                </div>
                                <div
                                  v-if="followQuestion.style !== null && followQuestion.style === 'Select'"
                                >
                                  <v-select
                                    :items="followQuestion.options"
                                    label="Answer Style"
                                  ></v-select>
                                </div>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                    text
                                    @click="testrepeat"
                                  >
                                    Answer
                                  </v-btn>
                                  <v-btn
                                    text
                                    @click="dialog.value = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </template>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  const axios = require('axios')

  export default {

    // bday en Your birth date is:
    // height en What is your height?
    // weight in lbs en What is your weight in pounds?
    // nic en In the past 5 years have you used any form of tobacco or nicotine (including e-cigs, patches or gum)?
    name: 'DashboardFormsWizard',
    data: () => ({
      follow: [],
      dialog: false,
      pickerDate: '',
      currentuser: '',
      followupquestion: '',
      questions: [],
      c1: 1,
      e6: 1,
      f6: 1,
      safeid: '',
      sitename: '',
      group: '',
      max: 50,
      allowSpaces: true,
      unique: true,
      userterm: {
        email: '',
        firstname: '',
        lastname: '',
        mobile: '',
        postcode: '',
        income: '',
        gender: '',
        goodhealth: '',
        resident: '',
        firsttime: '',
        bdayyear: '',
        beneficiary: '',
        bday: '',
        feet: '',
        inches: '',
        height: {
          feet: 0,
          inches: 0
        },
        weight: '',
        nic: ''
      },

      beneficiarylist: ['Spouse', 'Child', 'Children of this marriage', 'Sibling', 'Nephew', 'Nice', 'Parent', 'Cousin', 'Synagogue', 'University', 'Bank', 'Ex-Wife', 'Church', 'Partner', 'Charity', 'Other'],
      activePicker: 'YEAR',
      date: null,
      menu: false,
      filterchars: true,
      onlyspace: false,
    }),
    computed: {
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      followQuestion () {
        let quest = this.$store.getters.getfollowup
        return quest
      },
      validLocationTab () {
        let blockuser = true
        if (
          this.userterm.postcode &&
          this.userterm.resident &&
          this.userterm.income
        ) {
          blockuser = false
          // maybe they changed it so just update it again
          this.$store.dispatch('checkpostcode', this.userterm.postcode)
        }
        return blockuser
      },
      validHealthTab () {
        let blockuser = true
        if (
          this.userterm.feet &&
          this.userterm.weight &&
          this.userterm.bday &&
          this.userterm.gender
        ) {
          blockuser = false
        }
        return blockuser
      },
      rules () {
        const rules = []
        if (this.unique) {
          const rule =
            v => (!this.loadedsites.includes(v)) ||
              'Values must be unique and not be an existing site name'

          rules.push(rule)
        }
        if (!this.onlyspace) {
          const rule =
            v => v.trim().length || 'Name can not be only whitespace characters'

          rules.push(rule)
        }
        if (this.max) {
          const rule =
            v => (v || '').length <= this.max ||
              `A maximum of ${this.max} characters is allowed in a site name`

          rules.push(rule)
        }

        if (!this.allowSpaces) {
          const rule =
            v => (v || '').indexOf(' ') < 0 ||
              'No spaces are allowed'

          rules.push(rule)
        }
        if (this.filterchars) {
          const rule =
            v => !v.match(/\\t|[#\$\.]/gi) || ' $ # [ ] tabs are not allowed in a site name'
          rules.push(rule)
        }
        return rules
      },

    },
    watch: {
      menu (val) {
        val && setTimeout(() => {
          // 年から選ぶようにする
          this.$refs.picker.activePicker = 'YEAR'
          // 選び始めたら初期化
          this.pickerDate = null
        })
      },
      sitename: 'validateField',
      max: 'validateField',
      model: 'validateField',
    },
    created () {
    },
    beforeDestroy () {

      // now we know their details so lets set the classification

    },
    methods: {
      tagquestion (value) {
        this.$gtag.event('InitialSurveyTermLife', { action: value })
      },
      testrepeat () {
        this.$store.dispatch('processTestQuestion', 'testinfo')
      },
      submitquote () {
        let appdetails = this.$store.getters.getapplieddetails
        let directprovider = {
          provider: 'Direct Agent Processing',
          premium: '0',
          rank: 1
        }
        let requestdetails = {
          appdetails: appdetails,
          faceamount: 0,
          termlength: 'none',
          provider: directprovider
        }
        this.$store.dispatch('routepolicyrequest', requestdetails)
        this.$gtag.event('TermCoverageOptionsEnd')
        this.$router.push({ path: '/' })
      },
      getpostcodedata (postcode) {
        this.$store.dispatch('checkpostcode', postcode)
        this.e6 = '2'
        this.$gtag.event('InitialSurveyTermLife', { action: 'Postcode' })
      },
      getquestion (language, label) {
        let questiontext = 'unknown'
        if (!this.questions) {
          return
        }
        questiontext = this.questions.find(entry => {
          if (entry.label === label) {
            return true
          }
        })
        if (!questiontext) {
          return 'this I do not know'
        }
        return questiontext.question
      },
      nexttab (completedTab) {
        this.$gtag.event('InitialSurveyTermLife', { action: completedTab })
      },
      calcbmi () {
        let totalinches = (parseInt(this.userterm.feet * 12)) + parseInt(this.userterm.inches)
        let smoker = false
        if (this.userterm.nic === 'yes') {
          smoker = true
        }

        let rec = {
          height: { feet: this.userterm.feet, inches: this.userterm.inches },
          weight: this.userterm.weight,
          smoker: smoker
        }
        this.$gtag.event('InitialSurveyTermLife', { action: 'weight' })
        // this.$store.dispatch('calcBMI', rec)
      },
      selectcoverage () {
        // calc BMI
        let totalinches = (parseInt(this.userterm.feet * 12)) + parseInt(this.userterm.inches)
        let rec = {
          height: totalinches,
          weight: this.userterm.weight
        }
        // incase they changed it in the form
        let postcode = this.userterm.postcode
        this.$store.dispatch('checkpostcode', postcode)

        this.$store.dispatch('recordOptions', this.userterm)
        this.$gtag.event('InitialSurveyTermLife', { action: 'surveyComplete' })
        this.$router.push({ path: '/TermCoverage' })
      },
      save (date) {
        this.$refs.menu.save(date)

        this.userterm.bday = date
        this.$gtag.event('InitialSurveyTermLife', { action: 'birthDay' })
      },
    }
  }
</script>

<style scoped>
.test {
    border-width: 1px !important;
    border-color:#0d9eb0 !important;
    height: 100%;
    justify-content: center;
    text-align: center;
}
.blanketheader {
    font-family: 'formadjrdeckregular';
    color: 'red';
    font-size:1px;
    justify-content: center;
    text-align: center;
}
.rounded-card{
    border-radius:50px;
    background: "red";
}
.button-sections {
    display: flex;
    text-align: center;
    margin: 20px 0 0 0;
    font-size:18px;
    justify-content: center;
}

.button-sections > .yes {
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid #129fb1;
    padding: 12px 50px;
    margin: 1px;
    background: #129fb1;
    color: #fff;
}

.button-sections > .no {
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    color: #009db3;
    margin: 1px;
    border: 1px solid #129fb1;
    padding: 12px 50px;

}

.button-sections > .yes:hover {
    background: #005f6c;
    border: 1px solid #005f6c;
}

.button-sections > .no:hover {
    background: #eee;
}
</style>
<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>
